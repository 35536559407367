/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import portNotes from './i18n_portNotes';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'ua',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      ua: {
        translation: {
          forKwh: 'за',
          btns: {
            start: ' годин',
            startKwt: ' кВт*г',
            startFree: 'Спробувати безкоштовно',
            startFullCharge: 'Швидкий Старт',
            getAllStationsStatus: 'Показати всі станції',
            getNearestStationsStatus: 'Станції поруч',
          },
          historyEmpty: 'Історія зарядок відсутня',
          showPwaInstruction: 'Відео про додаток 220',
          title: 'Заряди 220 кілометрів за ніч',
          station: 'ПОРТ # ',
          feedbacks: 'Відгуки',
          leaveFeedback: 'Залишити відгук',
          chargeLink: 'Зарядка',
          landingLink: 'Список станцій',
          landingLinkMap: 'Мапа станцій',
          contacts: "Зв'язатися з нами",
          chooseVolume: 'Обрати',
          history: 'Історія зарядок',
          offer: 'Умови користування',
          wt: 'кВт*г',
          powerWt: ' кВт',

          energy: 'кВт',
          energyKwh: ' кВт*г',
          balance: 'Баланс:',
          currency: ' грн',
          payedForKwh: 'Оплачено',
          spent: 'Витрачено',
          v: 'В',
          km: ' км',
          chargedCongrats: 'Вітаємо!',
          chargedkWt: 'Заряджено: ',
          startNew: 'Хочу ще раз',
          power: 'Потужність',
          powerExceededError: 'Будь ласка, зменшіть потужність і спробуйте ще раз:',
          onlineStatus: 'Офлайн',
          powerKm: 'км/год',
          charging: 'Заряджено',
          voltage: 'Вольтаж',
          chargingSpeed: 'Швидкість заряду',

          allStationsStatusUnavailable: 'Наразі не доступно',
          getBloodyLitersSaved: 'Реінкарновано літрів',
          bloodyLitersLink: 'https://youtu.be/T0wK0VPy-V0?si=c9daReLH709PYE9q&t=83',
          noNearestStations: 'Поряд з вами станцій не виявлено',
          getDirection: 'Отримати маршрут',
          route: 'Маршрут',
          photo: 'Опис локації',
          distanceKm: ' км',
          distanceM: 'м',
          locationError: 'Увімкніть доступ до місцезнаходження в налаштуваннях браузера.',
          toHomePage: 'Станції поруч',

          login: 'Увійти з Google',
          loginTitle: 'Вітаємо',
          loginText: 'Увійдіть, щоб продовжити',

          logout: 'Вийти',

          userInfoName: 'Уввійшли як',

          slider: {
          kWhFor: 'кВт*г',
          aboutKm: 'біля',
          },
          historyPage: {
            station: 'Статус ПОРТ # ',
            amountS: 'Тривалість зарядки',
            price: 'Витрачено',
            spendEnergy: 'Витречено енергії',
            finished: 'Закінчено',
            refund: 'Повернуто',
            balance: 'Баланс',
            state: 'Статус',
            timeHours: 'год',
            timeMinutes: 'хв',
          },

          stopButton: 'Стоп',
          startButton: 'Почати',

          qrScanner: {
            nameButton: 'Сканувати QR-code',
            backButton: 'Назад',
            cameraPermissionAlert: 'Будь ласка, дозвольте доступ до камери та оновить сторінку',
          },

          errorStart: {
            alertMessagePort: `Помилка старту на ПОРТУ # {{portNumber}}`,
            alertMessage: 'Будь ласка спробуйте ще раз або зателефонуйте в службу підтримки:',
          },

          error: 'Помилка',
          errorDevHeader: 'Помилка серверу',
          errorDevBody: 'Ми вже вирішуємо проблему і скоро повернемось :)',
          errorOfflineHeader: 'ПОРТ # <1>{{stationNumber}}</1> офлайн :(',
          errorOfflineBody: 'Спробуйте, будь ласка, пізніше',
          errorPayHeader: 'Оплата недоступна!',
          errorPayBody: 'Ми вже вирішуємо проблему і скоро повернемось :)',
          errorStartFailedHeader: 'Ой, халепа :( Перевірте номер ПОРТУ та зробіть перевтик :)',
          errorStartFailedBody: '',
          errorFingerPrintUndefindHeader: 'Виявлений блокувальник реклами',
          errorFingerPrintUndefindBody: 'Будь ласка, вимкніть і спробуйте ще раз',
          errorFingerPrintUnavailableHeader: 'Безкоштовна зарядка вже використана',
          errorFingerPrintUnavailableBody: 'Здійсніть будь ласка оплату :)',

          stationOffline: 'Офлайн :(',

          costPerHour: ' грн за годину',
          costPerKwt: ' грн за кВт',
          costPerKwtAlt: ' грн за кВт',
          costPerKwtH: ' грн за кВт*г',

          readyForUse: 'Вільний!',

          calibration: 'Відкалібруйте результат!',
          enterYourKm: 'Будь ласка, введіть заряджені кілометри',
          sendKm: 'Калібрувати',
          calibratedKm: 'Ваш результат відкалібровано',
          btnRepeat: 'Повторити',

          featureInProgress: 'Чекайте в наступному оновленні :)',

          back: 'Повернутись',
          close: 'Закрити',
          delayedStart: 'Зарядка почнеться о <1>{{openFrom}}</1>',
          beforePayInfoKwh: 'Оплатити зарядну сесію',
          beforePayInfo: "Рекомендуємо обирати мінімальний об'єм, щоб НЕ накопичувати зайві баланси.",
          beforeStartInfo: 'Почати зарядну сесію',
          cancelDelayedStart: 'Відмінити',
          delayedInfo: 'Працює з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',
          leftBeforeStart: 'До старту залишилося:',
          startAt: 'Почнеться о <1>{{openFrom}}</1>',
          stationCardInfo: 'з <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',

          offerTitle: 'ПУБЛІЧНИЙ ДОГОВІР  ПРО НАДАННЯ ПОСЛУГ З ЗАРЯДКИ ЕЛЕКТРОТРАНСПОРТУ  (ДОГОВІР ПУБЛІЧНОЇ ОФЕРТИ).',
          contents: 'Дата публікації: 09.08.2024',
          callUs: 'Зателефонувати',
          contactUs: "Зв'язатися з нами",
          instagram: 'Інстаграм',
          telegram: 'Телеграм Бот',
          whatsApp: 'Ватсап',
          tapCall: 'Зателефонувати',
          tapChatBot: 'Отримати допомогу через чат',
          telHlib: 'Гліб',
          telMax: 'Макс',
          notFound: 'Сторінка не знайдена',
          btnPay: 'Оплатити',
          btnPayFor: 'Старт',
          overload: {
            waitForLink: 'Зачекайте секунду',
            gettingLink: 'Отримуємо посилання для оплати',
            successChecked: 'Вийшло',
            letsCharge: 'Погнали заряджати',
            checking: 'перевіряємо навантаження',
            overloadDetected: 'Виявлено перевантаження',
            slowdown: 'Будь ласка, зменшіть напругу до',
            repeat: 'і спробуйте ще раз',
          },
          footer: {
            part1: 'Всі права захищені',
            part2: 'Розроблено',
            part3: 'з ♥ до електромобілів',
          },

          b2bHeader: 'Для бізнесу',
          microgridHeader: 'Енергонезалежність',
          b2bNoStationsHeader: 'Ви ще не маєте станцій',
          b2bNoStationsBody: "Зв'яжіться з нами для замовлення",
          months: {
            january: 'Січень',
            february: 'Лютий',
            march: 'Березень',
            april: 'Квітень',
            may: 'Травень',
            june: 'Червень',
            july: 'Липень',
            august: 'Серпень',
            september: 'Вересень',
            october: 'Жовтень',
            november: 'Листопад',
            december: 'Грудень',
          },
          ...(portNotes.ua ? portNotes.ua : {}),
        },
      },
      ru: {
        translation: {
         slider: {
                  kWhFor: 'кВт*г',
                  aboutKm: 'около',
                  },
          forKwh: 'за',
          btns: {
            start: ' часов',
            startKwt: ' кВт*ч',
            startFree: 'Попробовать бесплатно',
            startFullCharge: 'Быстрый старт',
            getAllStationsStatus: 'Показать все станции',
            getNearestStationsStatus: 'Станции рядом',
          },
          historyEmpty: 'История зарядок отсутствует',
          showPwaInstruction: 'Видео о приложении 220',
          title: 'Заряди 220 километров за ночь',
          station: 'ПОРТ # ',
          feedbacks: 'Отзывы',
          leaveFeedback: 'Оставить отзыв',
          chargeLink: 'Зарядка',
          landingLink: 'Список станций',
          landingLinkMap: 'Карта станций',
          contacts: 'Связаться с нами',
          chooseVolume: 'Выбрать',
          history: 'История зарядок',
          offer: 'Условия использования',
          wt: 'кВт*ч',
          powerWt: ' кВт',

          energy: 'кВт',
          energyKwh: ' кВт*ч',
          balance: 'Баланс:',
          currency: ' грн',
          payedForKwh: 'Оплачено',
          spent: 'Потрачено',
          v: 'В',
          km: ' км',
          chargedCongrats: 'Поздравляем!',
          chargedkWt: 'Заряжено: ',
          startNew: 'Хочу еще раз',
          power: 'Мощность',
          powerExceededError: 'Пожалуйста, уменьшите мощность и попробуйте снова:',
          onlineStatus: 'Офлайн',
          powerKm: 'км/ч',
          charging: 'Заряжено',
          voltage: 'Вольтаж',
          chargingSpeed: 'Скорость заряда',

          allStationsStatusUnavailable: 'На данный момент недоступно',
          getBloodyLitersSaved: 'Сэкономлено литров',
          bloodyLitersLink: 'https://youtu.be/T0wK0VPy-V0?si=c9daReLH709PYE9q&t=83',
          noNearestStations: 'Рядом с вами станций не обнаружено',
          getDirection: 'Получить маршрут',
          route: 'Маршрут',
          photo: 'Описание локации',
          distanceKm: ' км',
          distanceM: 'м',
          locationError: 'Включите доступ к местоположению в настройках браузера.',
          toHomePage: 'Станции рядом',

          login: 'Войти с Google',
          loginTitle: 'Добро пожаловать',
          loginText: 'Войдите, чтобы продолжить',

          logout: 'Выйти',

          userInfoName: 'Вы вошли как',

          historyPage: {
            station: 'Статус ПОРТ # ',
            amountS: 'Длительность зарядки',
            price: 'Потрачено',
            spendEnergy: 'Потрачено энергии',
            finished: 'Завершено',
            refund: 'Возвращено',
            balance: 'Баланс',
            state: 'Статус',
            timeHours: 'час',
            timeMinutes: 'мин',
          },

          stopButton: 'Остановить',
          startButton: 'Начать',

          qrScanner: {
            nameButton: 'Сканировать QR-код',
            backButton: 'Назад',
            cameraPermissionAlert: 'Пожалуйста, разрешите доступ к камере и обновите страницу',
          },

          errorStart: {
            alertMessagePort: `Ошибка старта на ПОРТЕ # {{portNumber}}`,
            alertMessage: 'Пожалуйста, попробуйте снова или позвоните в службу поддержки:',
          },

          error: 'Ошибка',
          errorDevHeader: 'Ошибка сервера',
          errorDevBody: 'Мы уже решаем проблему и скоро вернемся :)',
          errorOfflineHeader: 'ПОРТ # <1>{{stationNumber}}</1> офлайн :(',
          errorOfflineBody: 'Попробуйте, пожалуйста, позже',
          errorPayHeader: 'Оплата недоступна!',
          errorPayBody: 'Мы уже решаем проблему и скоро вернемся :)',
          errorStartFailedHeader: 'Ой, проблема :( Проверьте номер ПОРТА и попробуйте снова :)',
          errorStartFailedBody: '',
          errorFingerPrintUndefindHeader: 'Обнаружен блокировщик рекламы',
          errorFingerPrintUndefindBody: 'Пожалуйста, отключите его и попробуйте снова',
          errorFingerPrintUnavailableHeader: 'Бесплатная зарядка уже использована',
          errorFingerPrintUnavailableBody: 'Пожалуйста, произведите оплату :)',

          stationOffline: 'Офлайн :(',

          costPerHour: ' грн за час',
          costPerKwt: ' грн за кВт',
          costPerKwtAlt: ' грн за кВт',
          costPerKwtH: ' грн за кВт*ч',

          readyForUse: 'Свободен!',

          calibration: 'Откалибруйте результат!',
          enterYourKm: 'Пожалуйста, введите заряженные километры',
          sendKm: 'Калибровать',
          calibratedKm: 'Ваш результат откалиброван',
          btnRepeat: 'Повторить',

          featureInProgress: 'Ожидайте в следующем обновлении :)',

          back: 'Вернуться',
          close: 'Закрыть',
          delayedStart: 'Зарядка начнется в <1>{{openFrom}}</1>',
          beforePayInfoKwh: 'Оплатить зарядную сессию',
          beforePayInfo: 'Рекомендуем выбирать минимальный объем, чтобы НЕ накапливать лишний баланс.',
          beforeStartInfo: 'Начать зарядную сессию',
          cancelDelayedStart: 'Отменить',
          delayedInfo: 'Работает с <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',
          leftBeforeStart: 'До начала осталось:',
          startAt: 'Начнется в <1>{{openFrom}}</1>',
          stationCardInfo: 'с <1>{{openFrom}}</1> до <1>{{closedFrom}}</1>',

          offerTitle:
            'ПУБЛИЧНЫЙ ДОГОВОР О ПРЕДОСТАВЛЕНИИ УСЛУГ ПО ЗАРЯДКЕ ЭЛЕКТРОТРАНСПОРТА (ДОГОВОР ПУБЛИЧНОЙ ОФЕРТЫ).',
          contents: 'Дата публикации: 09.08.2024',
          callUs: 'Позвонить',
          contactUs: 'Связаться с нами',
          instagram: 'Инстаграм',
          telegram: 'Телеграм Бот',
          whatsApp: 'Ватсап',
          tapCall: 'Позвонить',
          tapChatBot: 'Получить помощь через чат',
          telHlib: 'Глеб',
          telMax: 'Макс',
          notFound: 'Страница не найдена',
          btnPay: 'Оплатить',
          btnPayFor: 'Старт',
          overload: {
            waitForLink: 'Подождите секунду',
            gettingLink: 'Получаем ссылку для оплаты',
            successChecked: 'Успешно',
            letsCharge: 'Поехали заряжаться',
            checking: 'проверяем нагрузку',
            overloadDetected: 'Обнаружено перегрузка',
            slowdown: 'Пожалуйста, уменьшите напряжение до',
            repeat: 'и попробуйте снова',
          },
          footer: {
            part1: 'Все права защищены',
            part2: 'Разработано',
            part3: 'с ♥ к электромобилям',
          },

          b2bHeader: 'Для бизнеса',
          microgridHeader: 'Энергонезависимость',
          b2bNoStationsHeader: 'У вас еще нет станций',
          b2bNoStationsBody: 'Свяжитесь с нами для заказа',
          months: {
            january: 'Январь',
            february: 'Февраль',
            march: 'Март',
            april: 'Апрель',
            may: 'Май',
            june: 'Июнь',
            july: 'Июль',
            august: 'Август',
            september: 'Сентябрь',
            october: 'Октябрь',
            november: 'Ноябрь',
            december: 'Декабрь',
          },
          ...(portNotes.ru ? portNotes.ru : {}),
        },
      },
      en: {
        translation: {
         slider: {
                        kWhFor: 'kWh',
                        aboutKm: 'about',
                        },
          forKwh: 'for',
          energy: 'kWh',
          energyKwh: 'kWh',
          btns: {
            start: ' hours',
            startKwt: ' kWh',
            startFree: 'Try for free',
            startFullCharge: 'Just Start',
            getAllStationsStatus: 'Show all stations',
            getNearestStationsStatus: 'Show other stations',
          },
          historyEmpty: 'Charging history is empty',
          showPwaInstruction: 'Watch video about pwa',
          title: 'Charge 220 kilometers overnight',
          station: 'PORT  #',
          feedbacks: 'Feedbacks',
          leaveFeedback: 'Leave a feedback',
          chargeLink: 'Charging',
          landingLink: 'All stations',
          landingLinkMap: 'Map',
          contacts: 'Contact us',
          chooseVolume: 'Change',
          history: 'History',
          offer: 'Terms and conditions',
          wt: 'kWh',
          powerWt: ' kW',
          powerKwh: ' kWh',
          balance: 'Balance:',
          currency: ' uah',
          payedForKwh: 'Paid',
          spent: 'Spent',
          v: 'V',
          km: ' km',
          chargedCongrats: 'Congrats!!!',
          chargedkWt: 'Your car has been charged by: ',
          startNew: 'Go again',
          power: 'Power',
          powerExceededError: 'Please reduce power and try again:',
          onlineStatus: 'Offline',
          powerKm: 'km/h',
          charging: 'Charged',
          voltage: 'Voltage',
          chargingSpeed: 'Charging speed',

          allStationsStatusUnavailable: 'Unavailable now',
          getBloodyLitersSaved: 'Bloody liters saved',
          bloodyLitersLink: 'https://youtu.be/mk-LnUYEXuM?si=BFE0nuqLCCcU5P6o&t=95',
          noNearestStations: 'No stations found near you',
          getDirection: 'Get direction',
          route: 'Navigate',
          photo: 'Photo',
          distanceKm: ' km',
          distanceM: 'm',
          locationError: 'Please enable location access in your browser settings.',
          toHomePage: 'All stations',

          login: 'Log In with Google',
          loginTitle: 'Welcome',
          loginText: 'Log in to continue',

          logout: 'Log out',

          historyPage: {
            station: 'Status of PORT #',
            amountS: 'Duration',
            price: 'Price',
            spendEnergy: 'Spend energy',
            finished: 'Finished',
            refund: 'Refunded',
            balance: 'Balance',
            state: 'Status',
            timeHours: 'hours',
            timeMinutes: 'minutes',
          },

          stopButton: 'Stop',
          startButton: 'Start',

          qrScanner: {
            nameButton: 'Scan QR-code',
            backButton: 'Back',
            cameraPermissionAlert: 'Please allow camera in your browser permissions and reload',
          },

          userInfoName: 'Logged in as',

          errorStart: {
            alertMessage: `Error to start port # {{portNumber}}. 
            Please try again later or call us`,
          },

          error: 'Error',
          errorDevHeader: 'Server error.',
          errorDevBody: 'We are reinventing and will be back soon :)',
          errorOfflineHeader: 'Port #<1>{{stationNumber}}</1> is offline :(',
          errorOfflineBody: 'Please, try again later',
          errorPayHeader: 'Payment is unavailable!',
          errorPayBody: 'Sorry! We are reinventing and will be back soon :)',
          errorStartFailedHeader: 'Oops, something went wrong :(',
          errorStartFailedBody: '',
          errorFingerPrintUndefindHeader: 'Ad block detected',
          errorFingerPrintUndefindBody: 'Please, turn it off and try again',
          errorFingerPrintUnavailableHeader: 'Free charge is unavailable',
          errorFingerPrintUnavailableBody: 'Please pay:)',

          stationOffline: 'Offline :(',

          costPerHour: ' uah per hour',
          costPerKwt: ' uah per kWh',
          costPerKwtAlt: ' uah/kWh',
          costPerKwtH: ' uah per kWh',

          readyForUse: 'Ready!',

          calibration: 'Calibrate result!',
          enterYourKm: 'Please, enter your km',
          sendKm: 'Calibrate',
          calibratedKm: 'Your result was calibrated successfully',
          btnRepeat: 'Try Again',

          featureInProgress: 'Coming soon :)',

          back: 'Back',
          close: 'Close',

          delayedStart: 'Charging will begin at <1>{{openFrom}}</1>',
          beforePayInfoKwh: 'Pay for',
          beforePayInfo: 'Leftovers will be collected on your balance.',
          beforeStartInfo: 'Start session for ',
          cancelDelayedStart: 'Cancel',
          delayedInfo: 'Works from <1>{{openFrom}}</1> till <1>{{closedFrom}}</1>',
          leftBeforeStart: 'Will start in:',
          startAt: 'Will start at <1>{{openFrom}}</1>',
          stationCardInfo: 'from <1>{{openFrom}}</1> to <1>{{closedFrom}}</1>',

          callUs: 'Call Us',
          contactUs: 'Contact us',
          instagram: 'Instagram',
          telegram: 'Telegram Bot',
          whatsApp: 'WhatsApp',
          tapCall: 'Tap to call',
          tapChatBot: 'Telegram chat',
          telHlib: 'Hlib',
          telMax: 'Max',
          notFound: 'Page not found',
          btnPay: 'Pay',
          btnPayFor: 'Start',
          overload: {
            waitForLink: 'Wait a second',
            gettingLink: 'Getting payment link',
            btnPay: 'Pay',
            successChecked: 'Success',
            letsCharge: 'Let`s charge it',
            checking: 'is overloaded checking',
            overloadDetected: 'Overload',
            slowdown: 'Please, slow down to',
            repeat: 'and try again',
          },
          footer: {
            part1: 'All rights reserved',
            part2: 'Made by',
            part3: 'with ♥ to Zero Emission Vehicles',
          },

          b2bHeader: 'B2B',
          microgridHeader: 'Blackout solutions',
          b2bNoStationsHeader: 'No stations are available yet',
          b2bNoStationsBody: 'Contact us to order',
          months: {
            january: 'January',
            february: 'February',
            march: 'March',
            april: 'April',
            may: 'May',
            june: 'June',
            july: 'July',
            august: 'August',
            september: 'September',
            october: 'October',
            november: 'November',
            december: 'December',
          },
          ...(portNotes.en ? portNotes.en : {}),
        },
      },
    },
  });

export default i18n;
